@import "../../template/assets/scss/1-helpers/colors";


.logo_bar{
  max-width: 130px;
}

.active{
  background-color: $color_brand;
  color: $color_white;
}