@import "../../template/assets/scss/1-helpers/colors";

.cart_button{
    background-color: $color_brand;
    padding: 9px 13px;
    border-color: $color_brand;
    color: $color_stroke;
}

.left_button {
    border-radius: 7px 0 0 7px !important;
}

.right_button {
    border-radius: 0 7px 7px 0 !important;
}

.quantity {
    background-color: $color_brand2;
    padding: 9px;
    color: $color_dark;
    border-radius: 0 !important;
    min-width: 35px;
}

.sellerRow {
    max-height: 60vh;
}
