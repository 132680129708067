@import "../../template/assets/scss/1-helpers/colors";


.button {
    background-color: $color_stroke;
    color: $color_black;
    padding: 9px 13px;
    margin-top: 20px;
    border: 2px solid $color_brand;
    border-radius: 10px;
    width: 100%;
    font-size: 20px;
}

.active {
    background-color: $color_brand;
    color: $color_white;
}

.button:hover {
    background-color: $color_white;
    color: $color_black;
    border:2px solid $color_black;
}