// === Template colors

$color_stroke: #f1f1f8;
$color_brand: #8282d6;

$color_dark: #0b1b27;

$color_brand_light: rgba(86, 111, 254, 0.15);
$color_grad: radial-gradient(100% 100% at 53.13% 0%, #31e7fa 0%, #4477ff 52.6%, #74cdff 100%);

$color_text: #606c82;
$color_input: #f2f7fa;
$color_light: #ecdad5;
$color_body: #ecf1f9;
$color_hard_light: #e6edf0;
$color_white: #ffffff;
$color_black: #265e79;
$color_light_black: #316884;
$color_green: #36b37e;
$color_info: #5dadd3;
$color_red: #e44554;
$color-grey: #7a7685;
$color-grey-icon: #91a1bc;
$color-pink: #d9918c;

$color_yellow: #e6e046;
$color_brand2: #b1b2e9;

// === end

$maxMobile: "(max-width:767px)";
$maxSmall: "(max-width:991px)";
$minSmall: "(min-width:768px)";
$minMedium: "(min-width:992px)";
$minLarge: "(min-width:1200px)";

.is_color {
    $color_brand: $color_brand2;
}

// === Ui Kit

.color {
    .color_box {
        margin-bottom: 20px;
        .color_palette {
            border-radius: 10px;
            height: 100px;
            &.Brand {
                background-color: $color_brand;
            }
            &.Gradient {
                background: $color_grad;
            }
            &.Black {
                background-color: $color_black;
            }
            &.White {
                background-color: $color_white;
            }
            &.Light {
                background-color: $color_light;
            }
            &.Hard_light {
                background-color: $color_hard_light;
            }

            &.Brand_light {
                background-color: $color_brand_light;
            }
            &.Red {
                background-color: $color_red;
            }
            &.Green {
                background: $color_green;
            }
            &.Info {
                background-color: $color_info;
            }
            &.Stroke {
                background-color: $color_stroke;
            }
            &.Text {
                background-color: $color_text;
            }
        }
    }
}
