.floatingButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
   
    z-index: 1000;
  
  }
  
  .floating-icon {
    width: 60px; 
    height: 60px;
  }
  .floatingButton:hover{
    animation: bounce_613 0.4s linear;
  }