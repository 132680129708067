.requests {
    margin-bottom: -100px;
    position: relative;
    @include md {
        margin-bottom: -70px;
    }
    .requests__wrap {
        margin: 100px 0;
        @include md {
            margin: 70px 0;
        }
    }
    .contact__img {
        overflow: hidden;
        @include lg {
            display: none;
        }
        .img__wrap {
            img {
                height: 100%;
                position: absolute;
                right: 0
            }
        }
    }
    .requests_footer {
        display: flex;
        justify-content: space-between;
        @include sm {
            justify-content: center;
            flex-wrap: wrap;
        }
        .Attach_desc {
            display: flex;
            align-items: center;
            @include md {
                margin-right: 20px;
            }
            @include sm {
                margin-right: 0;
                margin-bottom: 20px;
            }
            .Attach_file {
                position: relative;
                display: block ruby;
                input[type="file"] {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    border-radius: 100px;
                    padding: 0;
                    margin: 0;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    cursor: pointer;
                    filter: alpha(opacity=0);
                    opacity: 0;
                }
            }
        }
    }
}