@import "../../template/assets/scss/1-helpers/colors";

.gs_button{
  border-radius: 10px;
  padding: 15px 20px;
  background-color: $color_brand;
  border: 2px solid $color_brand;
  color: $color_white;
}

.gs_button_right{
  border-radius: 10px;
  padding: 10px 10px;
  background-color: $color_brand;
  border: 2px solid $color_brand;
  color: $color_white;
  margin-left: auto; 
  }