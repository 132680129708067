@import "../../template/assets/scss/1-helpers/colors";

.pagination {
  display: flex;
  margin-top: 5px;
  justify-content: center;

  button.active {
    background-color: $color_brand;
    border: 2px solid $color_brand;
  }

  button:hover:not(.active) {
    background-color: $color_stroke;
    color: $color_black;
    border: 2px solid $color_black;
  }

  button {
    color: $color_white;
    margin: 0 2px;
    padding: 13px 15px;
    border-radius: 10px;
    background-color: $color_brand2;
    border: 2px solid $color_brand2;
    font-size: 20px;
  }

}