@import "../../template/assets/scss/1-helpers/colors";


.item {
  background-color: $color_stroke;
  border-radius: 10px;
  padding: 10px 0;
  justify-content: space-between;
}

.image {
  max-width: 130px;
  max-height: 160px;
  background-color: red;
}

.prices_row{
  justify-content: space-between;
}